import { useState, useEffect } from "react";
import "./bonosdisplay.css";

export default function BonosDisplay({
    emisores,
    selectedEmisor,
    ammount,
    dollar,
}) {
    const [annualReturn, setAnnualReturn] = useState(0);
    const [emisor, setEmisor] = useState({});

    useEffect(() => {
        setEmisor(emisores.filter((emisor) => emisor.id == selectedEmisor)[0]);
    }, [emisores, selectedEmisor, dollar]);

    useEffect(() => {
        let total = (ammount * emisor?.rate) / 100;
        setAnnualReturn(total);
    }, [emisor, ammount]);

    return (
        <div>
            <div className="resume">
                <div>
                    <h3 className="body-1">Capital invertido:</h3>
                    <p>
                        {dollar ? "USD" : "G."}{" "}
                        {ammount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </p>
                </div>
                <div>
                    <h3>Plazo de la inversión:</h3>
                    <p>
                        {emisor?.term} {emisor?.term >= 2 ? "años" : "año"}
                    </p>
                </div>
                <div>
                    <h3>Tasa promedio anual:</h3>
                    <p>
                        {emisor?.rate?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        %
                    </p>
                </div>
            </div>
            <div className="results">
                <div id="anual">
                    <h3>Rendimiento anual:</h3>
                    <p>
                        {dollar ? "USD" : "G."}{" "}
                        {annualReturn.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </p>
                </div>
                <div id="period">
                    <h3>Rendimiento del período:</h3>
                    <p>
                        {dollar ? "USD" : "G."}{" "}
                        {(annualReturn * emisor?.term).toLocaleString(
                            undefined,
                            {
                                maximumFractionDigits: 2,
                            }
                        )}
                    </p>
                </div>
                <div id="total">
                    <h3>Capital + Rendimiento del período:</h3>
                    <p>
                        {dollar ? "USD" : "G."}{" "}
                        {(
                            +ammount +
                            annualReturn * emisor?.term
                        ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </p>
                </div>
            </div>
            <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: emisor?.disclaimer }}
            ></div>
        </div>
    );
}
