import { NumberFormatBase } from "react-number-format";

export default function NumberInput(props) {
    const format = (numStr) => {
        if (numStr === "") return "";
        return new Intl.NumberFormat("de-DE").format(numStr);
    };

    return <NumberFormatBase {...props} format={format} />;
}
