import { useState, useEffect } from "react";
import "./select.css";

export default function Select({
    fondos,
    setAmmount,
    selectedEmisor,
    setSelectedEmisor,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (e) => {
        setSelectedEmisor(e.target.id);
        // para establecer los valores por defecto en la calculadora
        // 3 es el id del fondo mutuo en dólares, todos los demás son en guaraníes
        if (e.target.id !== "3") setAmmount(50000000);
        if (e.target.id === "3") setAmmount(10000);
        closeSelector();
    };

    const closeSelector = (e) => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="selector" style={{marginLeft: "0", padding: "10px 20px", fontSize: "15px"}} onClick={closeSelector}>
                {fondos
                    .filter((fondo) => fondo.id == selectedEmisor)
                    .map((fondo) => (
                        <div className="selected" key={fondo.id}>
                            <h4>{fondo.name}</h4>
                        </div>
                    ))}
            </div>
            <div className={isOpen ? "dropdown open" : "dropdown"}>
                {fondos.map((fondo) => (
                    <div
                        key={fondo.id}
                        className="option"
                        id={fondo.id}
                        onClick={handleClick}
                    >
                        {fondo.name}
                    </div>
                ))}
            </div>
        </>
    );
}
