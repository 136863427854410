import "./tabs.css";

export default function Tabs({ tab, setTab, setSelectedEmisor }) {
    const handleClick = (e) => {
        setTab(+e.target.id);
        setSelectedEmisor(1);
    };

    return (
        <div className="tabs-container">
            <button
                onClick={handleClick}
                id="1"
                className={tab === 1 ? "tab active" : "tab"}
            >
                Bonos
            </button>
            <button
                onClick={handleClick}
                id="2"
                className={tab === 2 ? "tab active" : "tab"}
            >
                CDAs
            </button>
            <button
                onClick={handleClick}
                id="3"
                className={tab === 3 ? "tab active" : "tab"}
            >
                Fondos Mutuos
            </button>
            <button
                onClick={handleClick}
                id="4"
                className={tab === 4 ? "tab active" : "tab"}
            >
                Fondos de Inversión
            </button>
        </div>
    );
}
