import Label from "../Label/Label";
import Tabs from "../Tabs/Tabs";
import "./simulator.css";
import BonosTab from "../Bonos/BonosTab/BonosTab";
import FondosTab from "../Fondos/FondosTab/FondosTab";
import InversionTab from "../Inversion/InversionTab/InversionTab";

export default function Simulator({
    tab,
    setTab,
    dollar,
    setDollar,
    ammount,
    setAmmount,
    selectedEmisor,
    setSelectedEmisor,
    bonos,
    cda,
    mutuos,
    inversion,
}) {
    const renderTab = (tab, dollar, setDollar) => {
        switch (tab) {
            case 1:
                return (
                    <BonosTab
                        // title="Bonos"
                        dollar={dollar}
                        setDollar={setDollar}
                        ammount={ammount}
                        setAmmount={setAmmount}
                        emisores={bonos}
                        selectedEmisor={selectedEmisor}
                        setSelectedEmisor={setSelectedEmisor}
                    />
                );

            case 2:
                return (
                    <BonosTab
                        // title="CDAs"
                        dollar={dollar}
                        setDollar={setDollar}
                        ammount={ammount}
                        setAmmount={setAmmount}
                        emisores={cda}
                        selectedEmisor={selectedEmisor}
                        setSelectedEmisor={setSelectedEmisor}
                    />
                );

            case 3:
                return (
                    <FondosTab
                        fondos={mutuos}
                        ammount={ammount}
                        tab={tab}
                        setAmmount={setAmmount}
                        selectedEmisor={selectedEmisor}
                        setSelectedEmisor={setSelectedEmisor}
                    />
                );

            case 4:
                return (
                    <InversionTab
                        fondos={inversion}
                        ammount={ammount}
                        tab={tab}
                        setAmmount={setAmmount}
                        selectedEmisor={selectedEmisor}
                        setSelectedEmisor={setSelectedEmisor}
                    />
                );

            default:
                break;
        }
    };

    return (
        <section className="simulator">
            <header>
                <Label
                    index="1"
                    label="Seleccioná un producto"
                    center={true}
                    bold={true}
                />
            </header>

            <Tabs
                tab={tab}
                setTab={setTab}
                setSelectedEmisor={setSelectedEmisor}
            />

            <div className="content">{renderTab(tab, dollar, setDollar)}</div>
        </section>
    );
}
