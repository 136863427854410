import { useState, useEffect } from "react";
import "./fondosdisplay.css";

export default function FondosDisplay({ fondo, ammount, days, currency }) {
    const [dailyReturn, setDailyReturn] = useState(0);
    const [totalReturn, setTotalReturn] = useState(0);

    useEffect(() => {
        // este useEffect hace los cálculos necesarios para mostrar el rendimiento final
        const rate = getDailyRate();
        const _dailyReturn = (ammount * (rate / 100)) / 365;
        setDailyReturn(_dailyReturn);
        const _periodReturn =
            ammount * (1 + rate / 100 / 365) ** days - ammount;
        setTotalReturn(_periodReturn + ammount);
    }, [ammount, fondo, days]);

    const getDailyRate = () => {
        // para sacar el porcentaje a mostrarse en la calculadora como rendimiento promedio
        let dailyRate;
        fondo?.data?.rendimientos.every((item) => {
            // si el max_amount es null significa que no hay máximo
            let max = item.max_amount
                ? item.max_amount
                : Number.MAX_SAFE_INTEGER;

            if (ammount >= item.min_amount && ammount < max) {
                dailyRate = item.rate;
                return false;
            }
            return true;
        });
        return dailyRate;
    };

    return (
        <>
            <div className="results">
                <div id="anual">
                    <h3>Rendimiento promedio*:</h3>
                    <p>{getDailyRate().toFixed(2).replace(".", ",")}%</p>
                </div>
                <div id="period">
                    <h3>Rendimiento diario:</h3>
                    <p>
                        {currency === "D" ? "USD" : "G."}{" "}
                        {dailyReturn?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </p>
                </div>
                <div id="total">
                    <h3>Capital + Rendimientos:</h3>
                    <p>
                        {currency === "D" ? "USD" : "G."}{" "}
                        {totalReturn.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </p>
                </div>
            </div>
            <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: fondo.disclaimer }}
            ></div>
        </>
    );
}
