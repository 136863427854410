import { useEffect, useState } from "react";
import "./fondostable.css";

function calcCrecimiento(currentAmount, rate) {
    let base = currentAmount * (1 + 0.085 / 365) ** 365;
    let premio = rate * currentAmount * (1 + 0.085 / 365) ** 365;
    let total = rate > 0 ? base + premio : base;
    return total;
}

function calcParaTodos(saldoInicial, aporteMensual, tasaAnual, premio) {
    let saldo = saldoInicial;
    let tasaMensual = tasaAnual / 12;
    let resultados = [];

    for (let i = 1; i <= 12; i++) {
        let ganancia;
        if (saldo === 0) {
            ganancia = aporteMensual * tasaMensual;
        } else {
            ganancia = saldo * tasaMensual;
        }
        saldo += aporteMensual + ganancia;
        resultados.push(Math.ceil(saldo));
    }

    const sumaResultados = resultados.reduce(
        (acumulador, resultado) => acumulador + resultado,
        0
    );
    const promedioResultados = sumaResultados / resultados.length;
    const premioAnual = promedioResultados * premio;

    return {
        resultados,
        premio: Math.ceil(premioAnual),
        total: Math.ceil(resultados[resultados.length - 1] + premioAnual),
    };
}

export default function FondosTable({ fondo, ammount }) {
    const [years, setYears] = useState([]);

    useEffect(() => {
        let total = 0;
        let results = [];
        const premios = [
            0.00, 0.005, 0.0075, 0.01, 0.0125, 0.0125, 0.0125, 0.0125, 0.0125,
            0.0125,
        ];
        // calculo para FM para Todos
        if (fondo.name?.includes("Todos")) {
            fondo.data?.rendimientos.forEach((rendimiento, index) => {
                let res = calcParaTodos(total, ammount, 0.08, premios[index]);
                total = res.total;
                results.push(total);
            });
        }
        // calculo para FM Crecimiento
        if (fondo.name?.includes("Crecimiento")) {
            let first = true;
            let currentAmount;
            fondo.data?.rendimientos.forEach((rendimiento) => {
                currentAmount = first ? ammount : currentAmount;
                first = false;
                currentAmount = calcCrecimiento(
                    currentAmount,
                    rendimiento.rate / 100
                );
                results.push(Math.ceil(currentAmount));
            });
        }
        setYears(results);
    }, [fondo, ammount]);

    return (
        <>
            <table className="fondos-table">
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Rendimiento</th>
                        <th>Evolución de la inversión</th>
                    </tr>
                </thead>
                <tbody>
                    {fondo.data?.rendimientos.map((rendimiento, index) => (
                        <tr
                            key={rendimiento.id}
                            className={rendimiento.prize ? "green" : ""}
                        >
                            <td>{index + 1}</td>
                            <td>
                                {(fondo.name?.includes("Todos")
                                    ? rendimiento.rate
                                    : rendimiento.rate + 8.5
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                                %
                            </td>
                            <td>
                                {years[index]?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                })}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: fondo.disclaimer }}
            ></div>
        </>
    );
}
