import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Simulator from "../../components/Simulator/Simulator";

export default function Calculadora() {
    const { hash } = useLocation();

    const [tab, setTab] = useState(1);
    const [dollar, setDollar] = useState(false);
    const [ammount, setAmmount] = useState(50000000);
    const [selectedEmisor, setSelectedEmisor] = useState(1);
    const [bonos, setBonos] = useState([
        {
            id: "1",
            emisor: "TELECEL SAE",
            rate: 7.7,
            term: 9,
            currency: "G",
        },
    ]);
    const [cda, setCda] = useState([
        {
            id: "1",
            emisor: "Financiera Paraguayo Japonesa",
            rate: 6.75,
            term: 1,
            currency: "G",
        },
    ]);
    const [mutuos, setMutuos] = useState([
        {
            id: "1",
            name: "Fondo Mutuo Disponible en Guaraníes",
            fund_type: "ms",
            currency: "G",
            disclaimer:
                "<p>* La rentabilidad de las cuotas partes es variable. Los rendimientos pasados no constituyen una garantía de los resultados futuros. La información indicada tiene únicamente fines informativos y no debe interpretarse como una oferta de venta o solicitud de una oferta de compra, ni como una recomendación con respecto a los valores del fondo.</p>",
            data: {
                rendimientos: [
                    {
                        id: 1,
                        rate: 4.75,
                        prize: false,
                        min_amount: 0,
                        max_amount: 999999999,
                    },
                    {
                        id: 2,
                        rate: 5.25,
                        prize: false,
                        min_amount: 1000000000,
                        max_amount: 4999999999,
                    },
                    {
                        id: 3,
                        rate: 5.5,
                        prize: false,
                        min_amount: 5000000000,
                        max_amount: null,
                    },
                ],
            },
        },
    ]);
    const [inversion, setInversion] = useState([
        {
            id: 1,
            name: "Fondo EcoForestal",
            fund_type: "is",
            currency: "G",
            disclaimer:
                "* La rentabilidad de las cuotas partes es variable. Los rendimientos pasados no constituyen una garantía de los resultados futuros. La información indicada tiene únicamente fines informativos y no debe interpretarse como una oferta de venta o solicitud de una oferta de compra, ni como una recomendación con respecto a los valores del fondo.",
            data: {
                meses: [
                    { id: 1, rate: 0 },
                    { id: 2, rate: 0 },
                    { id: 3, rate: 0 },
                    { id: 4, rate: 0 },
                    { id: 5, rate: 0 },
                    { id: 6, rate: 0.15 },
                    { id: 7, rate: 0 },
                    { id: 8, rate: 0 },
                    { id: 9, rate: 0 },
                    { id: 10, rate: 3.25 },
                ],
            },
        },
    ]);

    const setInitialTab = () => {
        if (hash === "#bonos" || hash.length < 1) setTab(1);
        if (hash === "#cda") setTab(2);
        if (hash === "#mutuos") setTab(3);
        if (hash === "#inversion") setTab(4);
    };

    useEffect(() => {
        setInitialTab();

        axios
            .get(process.env.REACT_APP_API_URL + "/bonos/")
            .then(function (response) {
                setBonos(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(process.env.REACT_APP_API_URL + "/cda/")
            .then(function (response) {
                setCda(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/fondos/?fund_type=ms&fund_type=mt"
            )
            .then(function (response) {
                setMutuos(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/fondos/?fund_type=is&fund_type=ic"
            )
            .then(function (response) {
                setInversion(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        // seleccionar emisor por defecto cuando cambias entre monedas
        let currency = dollar ? "D" : "G";
        if (tab === 1) {
            let bonosFiltered = bonos.filter(
                (bono) => bono.currency === currency
            );
            setSelectedEmisor(bonosFiltered[0].id);
        }
        if (tab === 2) {
            let cdaFiltered = cda.filter((c) => c.currency === currency);
            setSelectedEmisor(cdaFiltered[0].id);
        }
        if (tab === 4) {
            setSelectedEmisor(inversion[0].id);
        }
        // establecer valores por defecto según la moneda
        if (dollar) {
            setAmmount(10000);
        } else {
            setAmmount(50000000);
        }
    }, [dollar, tab, cda, bonos]);

    return (
        <div className="container">
            <h1 className="text-black">Simulador de inversión</h1>

            <Simulator
                tab={tab}
                setTab={setTab}
                dollar={dollar}
                setDollar={setDollar}
                ammount={ammount}
                setAmmount={setAmmount}
                selectedEmisor={selectedEmisor}
                setSelectedEmisor={setSelectedEmisor}
                bonos={bonos}
                cda={cda}
                mutuos={mutuos}
                inversion={inversion}
            />
        </div>
    );
}
