import { Routes, Route } from "react-router-dom";
import Calculadora from "./pages/Calculadora/Calculadora";
import PerfilInversionista from "./pages/PerfilInversionista/PerfilInversionista";

function App() {
    return (
        <main className="app">
            <Routes>
                <Route exact path="/" element={<Calculadora />} />
                <Route exact path="/perfil" element={<PerfilInversionista />} />
            </Routes>
        </main>
    );
}

export default App;
