import { useState } from "react";
import "./question.css";

function Option({
    handleChange,
    name,
    value,
    label,
    index,
    isSelected,
    setSelected,
    shouldShowOtherField,
    setShouldShowOtherField
}) {
    return (
        <>
            <label htmlFor={name + index} className="option">
                <div className={isSelected ? "radio selected" : "radio"}></div>
                <input
                    type="radio"
                    name={name}
                    value={value}
                    id={name + index}
                    onChange={(e) => {
                        setSelected(name + index);
                        setShouldShowOtherField(e.target.value === "0")
                        handleChange(e);
                    }}
                />
                <div>{label}</div>
            </label>
            {shouldShowOtherField && value === 0 ? (
                <div>
                    <input
                        className="other-field"
                        type="text"
                        name={name}
                        id={name + index}
                        placeholder="Explicanos brevemente..."
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default function Question({ index, handleChange, question, name, options }) {
    const [selected, setSelected] = useState("");
    const [shouldShowOtherField, setShouldShowOtherField] = useState(false);

    return (
        <div className="form-group" tabIndex={parseInt(index) + 4}>
            <h2>{question}</h2>
            <div className="underline" />
            {options.map(({ id, value, label }, index) => (
                <Option
                    key={id}
                    handleChange={handleChange}
                    value={value}
                    label={label}
                    name={name}
                    index={index}
                    isSelected={selected === name + index}
                    setSelected={setSelected}
                    shouldShowOtherField={shouldShowOtherField}
                    setShouldShowOtherField={setShouldShowOtherField}
                />
            ))}
        </div>
    );
}
