import Label from "../../Label/Label";
import Select from "../../Fondos/Select/Select";
import "./fondosdatos.css";
import { useEffect } from "react";
import NumberInput from "../../NumberInput";

export default function FondosDatos({
    fondo,
    fondos,
    ammount,
    setAmmount,
    days,
    setDays,
    selectedEmisor,
    setSelectedEmisor,
    currency,
}) {
    useEffect(() => {
        setAmmount(50000000);
    }, []);

    return (
        <>
            <Label index={2} bold={true} label="Seleccioná el Fondo Mutuo" />
            <Select
                fondos={fondos}
                setAmmount={setAmmount}
                selectedEmisor={selectedEmisor}
                setSelectedEmisor={setSelectedEmisor}
            />
            <div className="horizontal-input">
                <label htmlFor="ammount">
                    Inversión{" "}
                    {fondo.name?.includes("Todos") ? (
                        <span className="red">*</span>
                    ) : (
                        ""
                    )}
                </label>
                <div
                    className={
                        currency === "D"
                            ? "input-wrapper ammount usd"
                            : "input-wrapper ammount gs"
                    }
                >
                    <NumberInput
                        value={ammount}
                        id="ammount"
                        onValueChange={(values, sourceInfo) => {
                            if (values.value.length < 1) {
                                setAmmount(0);
                            } else {
                                setAmmount(values.floatValue);
                            }
                        }}
                    />
                </div>
            </div>
            {fondo.name?.includes("Todos") ? (
                <div className="disclaimer">
                    <span className="red">*</span> la inversión es mensual,
                    sumando 12 cuotas por año
                </div>
            ) : (
                ""
            )}
            {fondo.fund_type === "ms" ? (
                <div className="horizontal-input">
                    <label htmlFor="time">Plazo (días)</label>
                    <div className="input-wrapper">
                        <NumberInput
                            value={days}
                            id="time"
                            onValueChange={(values, sourceInfo) => {
                                if (values.value.length < 1) {
                                    setDays(0);
                                } else {
                                    setDays(values.floatValue);
                                }
                            }}
                        />
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
