import BonosDatos from "../BonosDatos/BonosDatos";
import BonosDisplay from "../BonosDisplay/BonosDisplay";
import "./bonostab.css";

export default function BonosTab({
    dollar,
    setDollar,
    ammount,
    setAmmount,
    emisores,
    selectedEmisor,
    setSelectedEmisor,
}) {
    return (
        <div className="tab-content">
            <div className="tab-data">
                <BonosDatos
                    dollar={dollar}
                    setDollar={setDollar}
                    ammount={ammount}
                    setAmmount={setAmmount}
                    emisores={emisores}
                    selectedEmisor={selectedEmisor}
                    setSelectedEmisor={setSelectedEmisor}
                />
            </div>
            <div className="tab-results">
                <BonosDisplay
                    emisores={emisores}
                    selectedEmisor={selectedEmisor}
                    ammount={ammount}
                    dollar={dollar}
                />
            </div>
        </div>
    );
}
