import "./label.css";

export default function Label({ index, label, center = false, bold = false }) {
    return (
        <div className={center ? "label center" : "label"}>
            <div className="index">{index}</div>
            <h2 className={bold ? "text-black" : null}>{label}</h2>
        </div>
    );
}
