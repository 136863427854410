import { useState, useEffect } from "react";
import InversionDatos from "../InversionDatos/InversionDatos";
import InversionTable from "../InversionTable/InversionTable";
import NavesTable from "../NavesTable/NavesTable";
import "./inversiontab.css";

export default function InversionTab({
    fondos,
    ammount,
    tab,
    setAmmount,
    selectedEmisor,
    setSelectedEmisor,
}) {
    const [fondo, setFondo] = useState({});
    const [currency, setCurrency] = useState("G");

    useEffect(() => {
        setCurrency(
            fondos.filter((fondo) => fondo.id == selectedEmisor)[0]?.currency
        );
        setFondo(fondos.filter((fondo) => fondo.id == selectedEmisor)[0]);
        // si el fondo es EcoForestal inicia en 30 millones de gs
        if(selectedEmisor == 5) setAmmount(30000000)
        // si el fondo es Naves inicia en 25 mil dls
        if(selectedEmisor == 6) setAmmount(25000)
    }, [selectedEmisor, fondos, tab]);

    return (
        <div className="tab-content">
            <div className="tab-data">
                <InversionDatos
                    fondo={fondo}
                    fondos={fondos}
                    setAmmount={setAmmount}
                    selectedEmisor={selectedEmisor}
                    setSelectedEmisor={setSelectedEmisor}
                    currency={currency}
                />
            </div>
            <div className="tab-results">
                {
                    fondo?.name?.includes("Eco") ?
                    <InversionTable fondo={fondo} ammount={ammount} /> :
                    <NavesTable fondo={fondo} ammount={ammount} />
                }
            </div>
        </div>
    );
}
