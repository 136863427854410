import Label from "../../Label/Label";
import Select from "../Select/Select";
import "./inversiondatos.css";
import { useEffect } from "react";
import AmmountSelect from "../AmmountSelect/AmmountSelect";

const MONTOS_ECO = [
    { id: 1, ammount: 30000000, label: "30.000.000" },
    { id: 2, ammount: 40000000, label: "40.000.000" },
    { id: 3, ammount: 50000000, label: "50.000.000" },
    { id: 4, ammount: 60000000, label: "60.000.000" },
    { id: 5, ammount: 70000000, label: "70.000.000" },
    { id: 6, ammount: 80000000, label: "80.000.000" },
    { id: 7, ammount: 90000000, label: "90.000.000" },
    { id: 8, ammount: 100000000, label: "100.000.000" },
    { id: 9, ammount: 110000000, label: "110.000.000" },
    { id: 10, ammount: 120000000, label: "120.000.000" },
    { id: 11, ammount: 130000000, label: "130.000.000" },
    { id: 12, ammount: 140000000, label: "140.000.000" },
    { id: 13, ammount: 150000000, label: "150.000.000" },
    { id: 14, ammount: 160000000, label: "160.000.000" },
    { id: 15, ammount: 170000000, label: "170.000.000" },
    { id: 16, ammount: 180000000, label: "180.000.000" },
    { id: 17, ammount: 190000000, label: "190.000.000" },
    { id: 18, ammount: 200000000, label: "200.000.000" },
    { id: 19, ammount: 210000000, label: "210.000.000" },
    { id: 20, ammount: 220000000, label: "220.000.000" },
    { id: 21, ammount: 230000000, label: "230.000.000" },
    { id: 22, ammount: 240000000, label: "240.000.000" },
    { id: 23, ammount: 250000000, label: "250.000.000" },
    { id: 24, ammount: 260000000, label: "260.000.000" },
    { id: 25, ammount: 270000000, label: "270.000.000" },
    { id: 26, ammount: 280000000, label: "280.000.000" },
    { id: 27, ammount: 290000000, label: "290.000.000" },
    { id: 28, ammount: 300000000, label: "300.000.000" },
    { id: 29, ammount: 310000000, label: "310.000.000" },
    { id: 30, ammount: 320000000, label: "320.000.000" },
    { id: 31, ammount: 330000000, label: "330.000.000" },
    { id: 32, ammount: 340000000, label: "340.000.000" },
    { id: 33, ammount: 350000000, label: "350.000.000" },
    { id: 34, ammount: 360000000, label: "360.000.000" },
    { id: 35, ammount: 370000000, label: "370.000.000" },
    { id: 36, ammount: 380000000, label: "380.000.000" },
    { id: 37, ammount: 390000000, label: "390.000.000" },
    { id: 38, ammount: 400000000, label: "400.000.000" },
    { id: 39, ammount: 450000000, label: "450.000.000" },
    { id: 40, ammount: 500000000, label: "500.000.000" },
    { id: 41, ammount: 550000000, label: "550.000.000" },
    { id: 42, ammount: 600000000, label: "600.000.000" },
    { id: 43, ammount: 650000000, label: "650.000.000" },
    { id: 44, ammount: 700000000, label: "700.000.000" },
    { id: 45, ammount: 750000000, label: "750.000.000" },
    { id: 46, ammount: 800000000, label: "800.000.000" },
    { id: 47, ammount: 850000000, label: "850.000.000" },
    { id: 48, ammount: 900000000, label: "900.000.000" },
    { id: 49, ammount: 950000000, label: "950.000.000" },
    { id: 50, ammount: 1000000000, label: "1.000.000.000" },
];

const MONTOS_NAVES = [
    { id: 1, ammount: 25000, label: "25.000" },
    { id: 2, ammount: 50000, label: "50.000" },
    { id: 3, ammount: 75000, label: "75.000" },
    { id: 4, ammount: 100000, label: "100.000" },
    { id: 5, ammount: 125000, label: "125.000" },
    { id: 6, ammount: 150000, label: "150.000" },
    { id: 7, ammount: 175000, label: "175.000" },
    { id: 8, ammount: 200000, label: "200.000" },
    { id: 9, ammount: 225000, label: "225.000" },
    { id: 10, ammount: 250000, label: "250.000" },
    { id: 11, ammount: 275000, label: "275.000" },
    { id: 12, ammount: 300000, label: "300.000" },
    { id: 13, ammount: 325000, label: "325.000" },
    { id: 14, ammount: 350000, label: "350.000" },
    { id: 15, ammount: 375000, label: "375.000" },
    { id: 16, ammount: 400000, label: "400.000" },
    { id: 17, ammount: 425000, label: "425.000" },
    { id: 18, ammount: 450000, label: "450.000" },
    { id: 19, ammount: 475000, label: "475.000" },
    { id: 20, ammount: 500000, label: "500.000" },
    { id: 21, ammount: 525000, label: "525.000" },
    { id: 22, ammount: 550000, label: "550.000" },
    { id: 23, ammount: 575000, label: "575.000" },
    { id: 24, ammount: 600000, label: "600.000" },
    { id: 25, ammount: 625000, label: "625.000" },
    { id: 26, ammount: 650000, label: "650.000" },
    { id: 27, ammount: 675000, label: "675.000" },
    { id: 28, ammount: 700000, label: "700.000" },
    { id: 29, ammount: 725000, label: "725.000" },
    { id: 30, ammount: 750000, label: "750.000" },
    { id: 31, ammount: 775000, label: "775.000" },
    { id: 32, ammount: 800000, label: "800.000" },
    { id: 33, ammount: 825000, label: "825.000" },
    { id: 34, ammount: 850000, label: "850.000" },
    { id: 35, ammount: 875000, label: "875.000" },
    { id: 36, ammount: 900000, label: "900.000" },
    { id: 37, ammount: 925000, label: "925.000" },
    { id: 38, ammount: 950000, label: "950.000" },
    { id: 39, ammount: 975000, label: "975.000" },
    { id: 40, ammount: 1000000, label: "1.000.000" },
    { id: 41, ammount: 1100000, label: "1.100.000" },
    { id: 42, ammount: 1200000, label: "1.200.000" },
    { id: 43, ammount: 1300000, label: "1.300.000" },
    { id: 44, ammount: 1400000, label: "1.400.000" },
    { id: 45, ammount: 1500000, label: "1.500.000" },
    { id: 46, ammount: 1600000, label: "1.600.000" },
    { id: 47, ammount: 1700000, label: "1.700.000" },
    { id: 48, ammount: 1800000, label: "1.800.000" },
    { id: 49, ammount: 1900000, label: "1.900.000" },
    { id: 50, ammount: 2000000, label: "2.000.000" },
];

export default function InversionDatos({
    fondo,
    fondos,
    setAmmount,
    selectedEmisor,
    setSelectedEmisor,
    currency,
}) {
    useEffect(() => {
        setAmmount(50000000);
    }, []);

    return (
        <>
            <Label
                index={2}
                bold={true}
                label="Seleccioná el Fondo de Inversión"
            />
            <Select
                fondos={fondos}
                setAmmount={setAmmount}
                selectedEmisor={selectedEmisor}
                setSelectedEmisor={setSelectedEmisor}
            />
            <Label index={3} label="Inversión" />
            <AmmountSelect
                selectedEmisor={selectedEmisor}
                setAmmount={setAmmount}
                currency={currency}
                montos={
                    fondo?.name?.includes("Eco") ? MONTOS_ECO : MONTOS_NAVES
                }
            />
        </>
    );
}
