import Label from "../../Label/Label";
import NumberInput from "../../NumberInput";
import Select from "../Select/Select";
import "./bonosdatos.css";

export default function BonosDatos({
    dollar,
    setDollar,
    ammount,
    setAmmount,
    emisores,
    selectedEmisor,
    setSelectedEmisor,
}) {
    const changeCurrency = (e) => {
        if (e.target.id === "gs") {
            setDollar(false);
        } else {
            setDollar(true);
        }
    };

    return (
        <>
            <div id="ammount">
                <Label
                    index={2}
                    bold={true}
                    label="Ingresá el capital a invertir"
                />

                <div className="currency-container">
                    <div className="currency-switch">
                        <div
                            className={
                                dollar ? "switch-marker usd" : "switch-marker"
                            }
                        ></div>
                        <label
                            id="gs"
                            className={dollar ? "" : "active"}
                            onClick={changeCurrency}
                        >
                            Guaraníes
                        </label>
                        <label
                            id="usd"
                            className={dollar ? "active" : ""}
                            onClick={changeCurrency}
                        >
                            Dólares
                        </label>
                    </div>
                    <div className="currency-input">
                        <label>{dollar ? "USD " : "G."}</label>
                        <NumberInput
                            value={ammount}
                            onValueChange={(values, sourceInfo) => {
                                if (values.value.length < 1) {
                                    setAmmount(0);
                                } else {
                                    setAmmount(values.floatValue);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <div id="emisor">
                <Label index={3} bold={true} label="Seleccioná el emisor" />
                <Select
                    dollar={dollar}
                    emisores={emisores}
                    selectedEmisor={selectedEmisor}
                    setSelectedEmisor={setSelectedEmisor}
                />
            </div>
        </>
    );
}
