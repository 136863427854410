import { useState, useEffect } from "react";
import axios from "axios";
import ImageDetails from "../../components/ImageDetails/ImageDetails";
import Popup from "../../components/Popup/Popup";
import Question from "../../components/Question/Question";
import "./perfilinversionista.css";

export default function PerfilInversionista() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [govId, setGovId] = useState("");
    const [phone, setPhone] = useState("");
    const [results, setResults] = useState([]); // lista de resultados posibles
    const [questions, setQuestions] = useState([]); // lista de preguntas con opciones
    const [choices, setChoices] = useState({}); // selección de las preguntas
    const [result, setResult] = useState(null); // resultado obtenido al calcular los puntos
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setChoices({
            ...choices,
            [name]: value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        // sumamos todos los puntos
        let points = Object.values(choices);
        let totalPoints = points.reduce(
            (total, point) => (total += parseInt(point)),
            0
        );

        // en base a los puntajes obtenidos asignamos calificaciones
        let profileCalculated;
        if (totalPoints <= results[0].points_to) {
            setResult(results[0]);
            profileCalculated = 1;
        }
        if (
            totalPoints >= results[1].points_from &&
            totalPoints <= results[1].points_to
        ) {
            setResult(results[1]);
            profileCalculated = 2;
        }
        if (totalPoints >= results[2].points_from) {
            setResult(results[2]);
            profileCalculated = 3;
        }
        // abrimos el popup para mostrar resultado
        setPopupIsOpen(true);

        // enviamos el formulario al admin
        axios
            .post(process.env.REACT_APP_API_URL + "/envios/", {
                full_name: fullName,
                email: email,
                phone: phone,
                gov_id: govId,
                result: profileCalculated,
                score: totalPoints,
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error.response.data);
            });
    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + "/resultados/")
            .then(function (response) {
                setResults(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(process.env.REACT_APP_API_URL + "/preguntas/")
            .then(function (response) {
                // handle success
                setQuestions(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

    return (
        <div className="perfil">
            {/* -------------  POPUP ---------- */}
            {popupIsOpen ? (
                <Popup result={result} setIsOpen={setPopupIsOpen} />
            ) : (
                ""
            )}
            {/* -------------  POPUP ---------- */}

            {/* ------------- ADORNOS ---------- */}
            {/* <ImageDetails /> */}
            {/* ------------- ADORNOS ---------- */}

            <div className="container">
                <h1>
                    Descubrí tu{" "}
                    <span className="green-text">perfil inversionista</span>
                </h1>
                <p className="intro">
                    En CADIEM INVERSIONES PARA TODOS, siempre buscamos darte un
                    mejor servicio, nos esforzamos para ofrecerte las
                    inversiones más adecuadas a tus necesidades.
                </p>

                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <h2>Datos del Inversionista</h2>
                        <div className="underline" />
                        <div className="form-row">
                            <div className="rounded-input">
                                <label htmlFor="name">Nombre y Apellido</label>
                                <input
                                    type="text"
                                    id="name"
                                    tabIndex="1"
                                    name="full_name"
                                    value={fullName}
                                    onChange={(e) =>
                                        setFullName(e.target.value)
                                    }
                                    placeholder="Juan González"
                                    required
                                />
                            </div>
                            <div className="rounded-input">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    tabIndex="2"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="juan.gonzalez@gmail.com"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="rounded-input">
                                <label htmlFor="gov_id">CI Nro</label>
                                <input
                                    type="text"
                                    id="gov_id"
                                    tabIndex="3"
                                    name="gov_id"
                                    value={govId}
                                    onChange={(e) => setGovId(e.target.value)}
                                    placeholder="954954"
                                    required
                                />
                            </div>
                            <div className="rounded-input">
                                <label htmlFor="phone">Teléfono</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    tabIndex="4"
                                    value={phone}
                                    onChange={(e) => {
                                        let regexp = new RegExp("^[0-9]*$");
                                        if (regexp.test(e.target.value)) {
                                            setPhone(e.target.value);
                                        }
                                    }}
                                    placeholder="0981 954 954"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {questions.map(({ question, name, options }, index) => (
                        <Question
                            key={index}
                            index={index}
                            handleChange={handleChange}
                            question={question}
                            options={options}
                            name={name}
                        />
                    ))}

                    <button className="submit-btn" type="submit" tabIndex={15}>
                        Ver mis resultados
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.68594 4.23341L5.77285 0.305214C5.36746 -0.101738 4.71945 -0.101738 4.31406 0.305214C3.89531 0.725579 3.89531 1.41803 4.31406 1.83839L7.46351 5L4.31406 8.16161C3.89531 8.58197 3.89531 9.27442 4.31406 9.69479C4.71945 10.1017 5.36746 10.1017 5.77285 9.69479L9.68594 5.76659C10.1047 5.34622 10.1047 4.65378 9.68594 4.23341Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    );
}
