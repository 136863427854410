import { useState, useEffect } from "react";
import "./select.css";

export default function Select({
    dollar,
    emisores,
    selectedEmisor,
    setSelectedEmisor,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [currency, setCurrency] = useState("G");

    useEffect(() => {
        setCurrency(dollar ? "D" : "G");
        setIsOpen(false);
    }, [dollar]);

    const handleClick = (e) => {
        setSelectedEmisor(e.target.id);
        closeSelector();
    };

    const closeSelector = (e) => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="selector" onClick={closeSelector}>
                {emisores
                    .filter((emisor) => emisor.id == selectedEmisor)
                    .map((emisor) => (
                        <div className="selected" key={emisor.id}>
                            <h4>{emisor.emisor}</h4>
                            <p>
                                TASA: {emisor.rate?.toFixed(2).replace(".", ",")}% | PLAZO: {emisor.term}
                            </p>
                        </div>
                    ))}
            </div>
            <div className={isOpen ? "dropdown open" : "dropdown"}>
                {emisores
                    .filter((emisor) => emisor.currency == currency)
                    .map((emisor) => (
                        <div
                            key={emisor.id}
                            className="option"
                            id={emisor.id}
                            onClick={handleClick}
                        >
                            {emisor.emisor} | {emisor.rate?.toFixed(2).replace(".", ",")}% | {emisor.term}{" "}
                            {emisor.term > 1 ? "años" : "año"}
                        </div>
                    ))}
            </div>
        </>
    );
}
