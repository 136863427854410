import { useState, useEffect } from "react";
import FondosDatos from "../FondosDatos/FondosDatos";
import FondosDisplay from "../FondosDisplay/FondosDisplay";
import FondosTable from "../FondosTable/FondosTable";
import "./fondostab.css";

export default function FondosTab({
    fondos,
    ammount,
    tab,
    setAmmount,
    selectedEmisor,
    setSelectedEmisor,
}) {
    const [fondo, setFondo] = useState({});
    const [days, setDays] = useState(30);
    const [currency, setCurrency] = useState("G");

    useEffect(() => {
        setCurrency(
            fondos.filter((fondo) => fondo.id == selectedEmisor)[0]?.currency
        );
        setFondo(fondos.filter((fondo) => fondo.id == selectedEmisor)[0]);
    }, [selectedEmisor, fondos, tab]);

    return (
        <div className="tab-content">
            <div className="tab-data">
                <FondosDatos
                    fondo={fondo}
                    fondos={fondos}
                    ammount={ammount}
                    setAmmount={setAmmount}
                    days={days}
                    setDays={setDays}
                    selectedEmisor={selectedEmisor}
                    setSelectedEmisor={setSelectedEmisor}
                    currency={currency}
                />
            </div>
            <div className="tab-results">
                {fondo.fund_type === "ms" ? (
                    <FondosDisplay
                        fondo={fondo}
                        ammount={ammount}
                        days={days}
                        currency={currency}
                    />
                ) : (
                    <FondosTable fondo={fondo} ammount={ammount} />
                )}
            </div>
        </div>
    );
}
