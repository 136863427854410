import { useEffect, useState } from "react";
import "./inversiontable.css";

export default function InversionTable({ fondo, ammount }) {
    const [months, setMonths] = useState([]);

    const calcMonths = () => {
        let results = [];
        fondo.data?.meses.forEach((month) => {
            let data = {
                id: month.id,
                rate: month.rate > 0 ? `${month.rate * 100}%` : "-",
                ammount:
                    month.rate > 0
                        ? `G. ${(ammount * month.rate)?.toLocaleString()}`
                        : "-",
            };
            results.push(data);
        });
        setMonths(results);
    };

    useEffect(() => {
        if (fondo && ammount) calcMonths();
    }, [ammount, fondo]);

    return (
        <>
        <div className="resume">
                <div>
                    <h3>Inversión:</h3>
                    <p>G. {parseInt(ammount).toLocaleString()}</p>
                </div>
                <div>
                    <h3>Rendimiento anual estimado:</h3>
                    <p>14%</p>
                </div>
            </div>
            <table className="inversion-table">
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Pagos</th>
                    </tr>
                </thead>
                <tbody>
                    {months.map(({ id, rate, ammount }) => (
                        <tr key={id} className={rate !== "-" ? "green" : ""}>
                            <td>{id}</td>
                            <td>{ammount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: fondo?.disclaimer }}
            ></div>
        </>
    );
}
