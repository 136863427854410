import { useState, useEffect } from "react";
import "./ammountselect.css";

export default function AmmountSelect({ selectedEmisor, montos, currency, setAmmount }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({});

    const handleClick = (e) => {
        setAmmount(e.target.id);
        setSelected(montos.filter((monto) => monto.ammount == e.target.id)[0]);
        closeSelector();
    };

    useEffect(() => {
        setSelected(
            selectedEmisor == 5
                ? { id: 1, ammount: 30000000, label: "30.000.000" }
                : { id: 1, ammount: 25000, label: "25.000" }
        );
    }, [selectedEmisor]);

    const closeSelector = (e) => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="selector" onClick={closeSelector}>
                <div className="selected">
                    <h4>
                        {currency === "G" ? "G." : "USD"} {selected.label}
                    </h4>
                </div>
            </div>
            <div
                className={
                    isOpen
                        ? "dropdown open ammount-select"
                        : "dropdown ammount-select"
                }
            >
                {montos.map((monto) => (
                    <div
                        key={monto.id}
                        className="option"
                        id={monto.ammount}
                        onClick={handleClick}
                    >
                        <span style={{ color: "#9a9a9a" }}>
                            {currency === "G" ? "G." : "USD"}{" "}
                        </span>
                        {monto.label}
                    </div>
                ))}
            </div>
        </>
    );
}
