import "./popup.css";

export default function Popup({ result, setIsOpen }) {
    return (
        <div className="result-popup">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 320 512"
                fill="currentColor"
                className="close-icon"
                role="button"
                onClick={() => setIsOpen(false)}
            >
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
            <h3>Tu perfil es:</h3>
            <h4>{result.profile}</h4>
            <p>{result.description}</p>
            <h5>
                Su cartera de inversión estaría diversificada de la siguiente
                manera:
            </h5>
            <ul>
                {result.distributions.map((item, index) => (
                    <li key={index}>{item.title}</li>
                ))}
            </ul>
            <button className="submit-btn" type="submit" onClick={() => window.location.href = "https://cadiem.com.py/contacto/"}>
                Contactá un asesor
                <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.68594 4.23341L5.77285 0.305214C5.36746 -0.101738 4.71945 -0.101738 4.31406 0.305214C3.89531 0.725579 3.89531 1.41803 4.31406 1.83839L7.46351 5L4.31406 8.16161C3.89531 8.58197 3.89531 9.27442 4.31406 9.69479C4.71945 10.1017 5.36746 10.1017 5.77285 9.69479L9.68594 5.76659C10.1047 5.34622 10.1047 4.65378 9.68594 4.23341Z"
                        fill="white"
                    />
                </svg>
            </button>
        </div>
    );
}
