import { useEffect, useState } from "react";
import "./navestable.css";

export default function NavesTable({ fondo, ammount }) {
    const [months, setMonths] = useState([]);
    const [cuotas, setCuotas] = useState(0);
    const [dividendos, setDividendos] = useState(0);
    const [dividendosExtras, setDividendosExtras] = useState(0);
    const [total, setTotal] = useState(0);

    const calcMonths = () => {
        let results = [];
        setCuotas(ammount / 25000);
        fondo.data?.meses.forEach((month) => {
            let dividendos = fondo.ordinary_dividends * cuotas;
            let dividendosExtras =
                month.extra_dividends > 0
                    ? month.extra_dividends * cuotas
                    : "-";
            let acumulado =
                dividendosExtras !== "-"
                    ? dividendos + dividendosExtras + fondo.capital * cuotas
                    : dividendos + fondo.capital * cuotas;

            // comienza a poblar el array de meses
            let data = {
                id: month.id,
                dividendos,
                dividendosExtras,
                acumulado: acumulado,
            };
            results.push(data);
        });
        setMonths(results);

        let sumaDividendos = 0;
        results.forEach(({ dividendos }) => (sumaDividendos += dividendos));
        setDividendos(sumaDividendos);

        let sumaDividendosExtras = 0;
        results.forEach(({ dividendosExtras }) => {
            if (dividendosExtras !== "-")
                sumaDividendosExtras += dividendosExtras;
        });
        setDividendosExtras(sumaDividendosExtras);

        let sumaTotal = 0;
        results.forEach(({ acumulado }) => (sumaTotal += acumulado));
        setTotal(sumaTotal - ammount);
    };

    useEffect(() => {
        if (fondo && ammount) calcMonths();
    }, [ammount, fondo, cuotas]);

    return (
        <>
            <div className="resume">
                <div>
                    <h3>Cuotas invertidas:</h3>
                    <p>{cuotas}</p>
                </div>
                <div>
                    <h3>TIR del proyecto<span className="red">*</span>:</h3>
                    <p>9,91%</p>
                </div>
                <div>
                    <h3>Dividendos ordinarios<span className="red">*</span>:</h3>
                    <p>
                        USD {fondo?.ordinary_dividends?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) || 0}
                    </p>
                </div>
            </div>
            <div className="disclaimer">
                <span className="red">*</span> los montos son estimados.
            </div>
            <table className="inversion-table" id="naves">
                <thead>
                    <tr>
                        <th>Semestre</th>
                        <th>Dividendos<span className="red">*</span></th>
                        <th>Div. Extraordinarios<span className="red">*</span></th>
                        <th>Acumulado</th>
                    </tr>
                </thead>
                <tbody>
                    {months.map(
                        ({ id, dividendos, dividendosExtras, acumulado }) => (
                            <tr key={id} className="text-green">
                                <td>{id}</td>
                                <td>
                                    {dividendos.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </td>
                                <td>
                                    {dividendosExtras !== "-"
                                        ? dividendosExtras.toLocaleString(
                                            undefined,
                                            {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                        : dividendosExtras}
                                </td>
                                <td>
                                    {acumulado.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </td>
                            </tr>
                        )
                    )}
                    <tr className="totales">
                        <td>Totales</td>
                        <td>
                            USD{" "}
                            {dividendos.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td>
                        <td>
                            USD{" "}
                            {dividendosExtras.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td>
                        <td>
                            USD{" "}
                            {total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: fondo?.disclaimer }}
            ></div>
        </>
    );
}
